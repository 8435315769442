/* ==========================================================================
   Variables
   ========================================================================== */
   
$gray                       : #7a8288 !default;
$darkest-gray               : mix(#000, $gray, 20%) !default;
$dark-gray                  : mix(#000, $gray, 40%) !default;
$darker-gray                : mix(#000, $gray, 60%) !default;
$light-gray                 : mix(#fff, $gray, 50%) !default;
$lighter-gray               : mix(#fff, $gray, 90%) !default;
$border-color               : $lighter-gray !default;
$body-color                 : #fff !default;
$background-color           : #fff !default;
$code-background-color      : #e7e7e7 !default;
$code-background-color-dark : $light-gray !default;
$text-color                 : $dark-gray !default;
$border-color               : $lighter-gray !default;
$primary-color              : #515659 !default;
$success-color              : #62c462 !default;
$warning-color              : #f89406 !default;
$danger-color               : #ee5f5b !default;
$info-color                 : #52adc8 !default;
$link-color                 : $info-color !default;
$link-color-hover           : mix(#000, $link-color, 25%) !default;
$link-color-visited         : mix(#fff, $link-color, 25%) !default;
$masthead-link-color        : $primary-color !default;
$masthead-link-color-hover  : mix(#000, $primary-color, 25%) !default;
$medium-wide                : 900px !default;

/* type scale */
$type-size-1                  : 2.441em !default;  // ~39.056px
$type-size-2                  : 1.953em !default;  // ~31.248px
$type-size-3                  : 1.563em !default;  // ~25.008px
$type-size-4                  : 1.25em !default;   // ~20px
$type-size-5                  : 0.8rem !default;      // ~16px
$type-size-6                  : .9em !default;   // ~12px
$type-size-7                  : 0.6875em !default; // ~11px
$type-size-8                  : 0.8em !default;  // ~10px
   
// Шрифт на странице

#main {
  font-size: .8em;
}

body {
  line-height: 1.7 !important; //межстрочный интервал
}

// Ссылки на странице
a {
	text-decoration: none !important;
	color: inherit;

	&:focus {
		@extend %tab-focus;
	}

	&:visited {
		  color: $link-color-visited;
	}
	
	&:visited:hover {
		  color: $link-color-hover;
	}

	&:hover,
	&:active {
		color: $link-color-hover;
		outline: 0;
	}

}

a img {
	vertical-align: bottom !important;
}

// Полоса прокрутки 
html::-webkit-scrollbar
{
	width: 13px;
	background-color: #F5F5F5;
	height: 0px; /* for horizontal scrollbars */
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 3px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 3px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #999;
}
  
.toc::-webkit-scrollbar //Ширина скроллбара в toc
{
	width: 4px !important;
}


// Таблицы 
thead {
  border: 2px solid mix(#000, $border-color, 25%) !important;
  background-color: $lighter-gray;
}

td {
	border: 1px solid mix(#000, $border-color, 25%);
	// font-weight: normal !important;

	a {
      color: #52adc8 !important;
	  
 	  &:hover {
        color: mix(#000, #52adc8, 30%) !important;
      }
    }
}

th {
  border: 2px solid mix(#000, $border-color, 25%) !important;
  text-align: center !important;
  background-color: $border-color !important;
  border-bottom: 2px solid mix(#000, $border-color, 25%) !important;
}
 
// Кнопки 
.btn {
  &--inversed {
    color: $dark-gray !important;
    border: 2px solid $light-gray !important; /* override*/
    background-color: #fff;

    &:hover {
      color: #fff !important;
      border-color: $gray;
    background-color: $darkest-gray !important;
    }
  }
  &--short {
    color: $dark-gray !important;
    font-size: $type-size-4 !important;
    border: 2px solid $light-gray !important; /* override*/
    background-color: #fff;
	width: 110px;
	display: inline-block !important;
	padding: 0.25em 0.5em 0.39em 0.5em!important;
    text-align: center !important;

    &:hover {
      color: #fff !important;
      border-color: $gray;
    background-color: $darkest-gray !important;
    }
  }
}

// Фон и обводка футера
.page__footer {
  color: mix(#fff, $gray, 25%);
  background-color: $lighter-gray;
  border-top: 1px solid $light-gray; // Верхняя линия футера
}


// Верхнее меню 
.masthead__menu-item {
  font-size: 75%; // Ширина верхнего меню
}

.greedy-nav {
  background: $background-color;

  button {
    background-color: $primary-color;
    color: #fff;
  }
  
  .visible-links {
	text-align: center;
	margin-left: 20px;
	margin-right: 0px !important;
	padding-right: 5px !important;
	// Эти две строчки центрируют меню по вертикали
	display: flex !important;
	align-items: center !important;

    a {
      position: relative;
	  margin-left: 10px;
	  margin-right: 10px;
	  
	  &:hover {
        color: $masthead-link-color-hover;
      }
	}
  }
  
 //центрируем название сайта 
.site-title {
	display: flex !important;
	align-items: center !important;
}
  
  .hidden-links {
    background: #fff;
	text-align: left;
    padding: 5px !important;

    a {
      &:hover {
        color: $masthead-link-color-hover;
        background: mix(#fff, $primary-color, 75%);
      }
    }

    &:before {
      right: 10px;
      border-color: $border-color transparent;
    }

    &:after {
      right: 10px;
    }

    li {
      border-bottom: 1px solid $border-color;
      }
    }
}


// Цвет ссылок в notice
@mixin notice($notice-color) {
  font-weight: normal;
  background-color: mix(#fff, $notice-color, 90%);
  
  a {
    color: $notice-color;


    &:hover {
      color: mix(#000, $notice-color, 40%);
    }
  }

  code {
    background-color: mix(#fff, $notice-color, 95%)
  }
}

// Размер шрифта notice
.notice {
  font-size: 1em !important;
}

.notice--primary {
  font-size: 1em !important;
  
	a {
      color: $primary-color !important; // цвет ссылки в notice-primary
	  
 	  &:hover {
        color: mix(#fff, $primary-color, 40%) !important;
      }
    }
}

.notice--info {
  font-size: 1em !important;
}

.notice--warning {
  font-size: 1em !important;
}

.notice--success {
  font-size: 1em !important;
}

.notice--danger {
  font-size: 1em !important;
}

@mixin notice($notice-color) {
  margin: 1.5em 0 !important;  // Вертикальные маргины между нотайсами
}

.notice {
  @include notice($dark-gray);
}

  
// Ширина текстового поля в шапке страницы home
.page__hero {
  &--overlay {
	 padding-bottom: 35px !important;
	 
    .page__lead {
      max-width: 900px !important;
    }
  }
}


// Сайдбар (может нужно добавлять в sidebar.scss)
.sidebar {
	 a {
		display: block;
		margin-bottom: 5px;
		padding-right: 5px;
		padding-top: 2px;
		padding-bottom: 2px;
		color: $text-color;
		font-size: 0.96em !important;
		text-decoration: none; 

		&:hover {
		  opacity: 0.5;
		}

	}
}

  
.sidebar__right {
    &.sticky {
      overflow-y: auto;
      /* calculate height of nav list */
    }
}

// .sidebar__right {
  // @include breakpoint($large) { Эту часть нужно добавить в sidebar.scss"
	//float: right;
	//position: sticky;
    //top: -20px !important;
  // }
// }



.page__content {

  p, li, dl {
    font-size: 1em !important;
  }
}

@mixin notice($notice-color) {
  font-size: 1em !important;
}

hr {
    border: 0 !important;
    height: 1px  !important;
    background: #333 !important;
    background-image: linear-gradient(to right, #fff, #969696, #b0adad, #969696, #fff) !important;
	}


// toc
.toc {
	font-family: Arial, Helvetica, sans-serif;
	text-transform: none !important;
	letter-spacing: 0.4px !important;
	max-height: 75vh !important;
	min-height: auto !important; 
	min-width: auto !important; 
	max-width: auto !important;
    font-size: $type-size-5;
	overflow-y: auto !important;
	
	.nav__title {
	  text-transform: uppercase;
	  padding: 0.3rem 0.8rem 0.3rem 0.8rem !important;
      font-size: $type-size-5;
	}

	a {
	   padding: 0.3rem 0.2rem 0.3rem 0.8rem !important;
	   color: $text-color !important;
		  
	  &:hover {
		color: $light-gray !important;
	  }
	}
}

  .toc__menu {

    a {
      font-size: 0.85em !important;
      }
    }

  
// Отступы в toc  
nav {
  ul {
    margin: 0;
    padding: 0px 10px 0px 10px !important;
  }

  li {
    list-style: none;
  }

  a {
    text-decoration: none;
	  &:hover {
        color: $gray !important;
      }
  }

  /* override white-space for nested lists */
  ul li,
  ol li {
    margin-bottom: 0;
  }

  li ul,
  li ol {
    margin-top: 0;
  }
}

// Стиль кнопки наверх
.scrollup {
	width:40px;
	height:40px;
	opacity:0.6;
	position:fixed;
	bottom:30px;
	right:50px;
	display:none;
	text-indent:-9999px;
	background: url('images/top.png') no-repeat;
	
  &:hover {
	opacity:1;
  }
}

  .nav__sub-title {
    font-size: $type-size-5 !important; //ссылки в аддонах
  }

// Добавить вручную в base.css
// p > code,
// a > code,
// li > code,
// figcaption > code,
// td > code {
  // background: $code-background-color;
  // border: 1px solid $light-gray;
// }
